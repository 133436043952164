import revive_payload_client_hy1IdDGJbA from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.33.0_@types+pg@_4adtmvx7yoq5l7wj2f3lqrgceu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_v4srvbe1l8 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.33.0_@types+pg@_4adtmvx7yoq5l7wj2f3lqrgceu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_KA2RuBd3kd from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.33.0_@types+pg@_4adtmvx7yoq5l7wj2f3lqrgceu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_YRuNr8RpFP from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.33.0_@types+pg@_4adtmvx7yoq5l7wj2f3lqrgceu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_ZKSC9sFeI6 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.33.0_@types+pg@_4adtmvx7yoq5l7wj2f3lqrgceu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_ZAdPiR7Xle from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.33.0_@types+pg@_4adtmvx7yoq5l7wj2f3lqrgceu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_DwlA0LBTj3 from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.33.0_@types+pg@_4adtmvx7yoq5l7wj2f3lqrgceu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_69vZ2wCBNB from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typescript@5.6.3_vue@3.5.13_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/centre-supervisor-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_qNhVehSKLv from "/app/centre-supervisor-nuxt/node_modules/.pnpm/nuxt@3.15.0_@parcel+watcher@2.5.0_@types+node@22.10.3_db0@0.2.1_drizzle-orm@0.33.0_@types+pg@_4adtmvx7yoq5l7wj2f3lqrgceu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_3I2p9n4A7D from "/app/centre-supervisor-nuxt/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.29.1/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import primevue_plugin_egKpok8Auk from "/app/centre-supervisor-nuxt/.nuxt/primevue-plugin.mjs";
import plugin_client_j57kaOkoGl from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@primevue+nuxt-module@4.2.5_patch_hash=y2bjtiada6cj3qvvllbuctyaka_@babel+parser@7.26.3_magica_oelynjnkmkd5ss2imh2ges4nkm/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_iRMZWhTb0w from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_NTczswGlkY from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@nuxtjs+i18n@8.5.6_magicast@0.3.5_rollup@4.29.1_vue@3.5.13_typescript@5.6.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_KCvMs2FbTL from "/app/centre-supervisor-nuxt/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.29.1_typesc_aeqlaaqny3u7rougoh5kgbzfr4/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import trpc_IJvhwZlfLl from "/app/backend/plugins/trpc.ts";
import chartjs_1wC9lAM0Tp from "/app/centre-supervisor-nuxt/plugins/chartjs.ts";
import hookVueError_FUSNmNXceB from "/app/centre-supervisor-nuxt/plugins/hookVueError.ts";
export default [
  revive_payload_client_hy1IdDGJbA,
  unhead_v4srvbe1l8,
  router_KA2RuBd3kd,
  payload_client_YRuNr8RpFP,
  navigation_repaint_client_ZKSC9sFeI6,
  check_outdated_build_client_ZAdPiR7Xle,
  chunk_reload_client_DwlA0LBTj3,
  plugin_vue3_69vZ2wCBNB,
  components_plugin_KR1HBZs4kY,
  prefetch_client_qNhVehSKLv,
  plugin_3I2p9n4A7D,
  primevue_plugin_egKpok8Auk,
  plugin_client_j57kaOkoGl,
  switch_locale_path_ssr_iRMZWhTb0w,
  i18n_NTczswGlkY,
  plugin_KCvMs2FbTL,
  trpc_IJvhwZlfLl,
  chartjs_1wC9lAM0Tp,
  hookVueError_FUSNmNXceB
]